import React, { useState } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import Layout from "../components/layout";
import { gsap } from "gsap";
import SEO from "../components/seo";
const ProjectsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  button {
    cursor: pointer;
  }
  h2 {
    font-size: 40px;
    font-family: "Eames Century Modern";
    font-style: italic;
    line-height: 48px;
    vertical-align: middle;
  }
  p {
    font-family: "Brandon Text";
    font-size: 30px;
    line-height: 40.61px;
  }
  p:nth-child(3) {
    margin-top: 30px;
  }
  #header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  span {
    font-family: "Brandon Text";
  }
  @media screen and (max-width: 906px) {
    p:nth-child(3) {
      margin-top: 10px;
    }
  }
`;
const CheatWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  @media screen and (max-width: 1700px) {
    max-width: 1700px;
  }
  @media screen and (max-width: 1500px) {
    max-width: 1500px;
  }
  @media screen and (max-width: 1291px) {
    max-width: 1291px;
  }
  @media screen and (max-width: 1111px) {
    max-width: 1111px;
  }
  @media screen and (max-width: 1019px) {
    max-width: 1019px;
  }
  @media screen and (max-width: 906px) {
    max-width: 906px;
  }
  @media screen and (max-width: 809px) {
    max-width: 809px;
  }
  @media screen and (max-width: 700px) {
    max-width: 700px;
  }
  @media screen and (max-width: 593px) {
    max-width: 593px;
  }
  @media screen and (max-width: 415px) {
    max-width: 415px;
  }
`;
const CoverPage = styled(BgImage)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 1080px;
  overflow: none;
  h1 {
    font-family: "Eames Century Modern";
    font-style: italic;
    font-size: 35px;
    line-height: 42px;
    font-weight: 400;
    width: 1042px;
  }

  .arrowSmall {
    display: none;
  }
  @media screen and (max-width: 1111px) {
    max-width: 1111px;

    #LOGO {
      height: auto !important;
      width: 275px;
      margin: auto;
    }
    h1 {
      width: 1000px;
      font-size: 28px;
    }
  }
  @media screen and (max-width: 1019px) {
    h1 {
      width: 800px;
    }
  }
  @media screen and (max-width: 817px) {
    h1 {
      width: 600px;
    }
  }
  @media screen and (max-width: 621px) {
    #LOGO {
      width: 200px;
    }
    h1 {
      font-size: 20px;
      line-height: 32px;
      width: 420px;
    }
    .arrowBig {
      display: none;
    }
    .arrowSmall {
      margin-top: 60px;
      width: 100%;
      display: block;
    }
  }
  @media screen and (max-width: 437px) {
    #LOGO {
      width: 202.75px;
    }
    .arrowBig {
      display: none;
    }
    .arrowSmall {
      margin-top: 80px;
      width: 100%;
      display: block;
    }
    h1 {
      margin-top: 0;
      line-height: 24px;
      max-width: 333px;
    }
  }
`;

const ProjectsGlobalWrapper = styled.div`
  h1 {
    font-family: "Brandon Grotesque";
    font-size: 70px;
    font-weight: 700;
    color: #f36f4b;
  }
  button {
    text-decoration: none;
    border: 4px solid #f36f4b;
    font-family: "Brandon Grotesque";
    font-size: 20px;
    font-weight: 700;
    color: #f36f4b;
    padding: 4px;
    background: none;
  }
  button:hover {
    text-decoration: underline;
  }
  p {
    font-family: "Brandon Text";
    font-size: 28px;
    text-align: right;
    line-height: 143%;
    color: #f36f4b;
  }
  .rightColumnCoverWrapper {
    position: relative;
  }
  .quote {
    width: 80%;
  }
  .quoteAuthor {
    font-family: Eames Century Modern;
    font-style: italic;
    font-weight: normal;
    font-size: 25px;
  }
  .projTitleCTA {
    grid-area: title;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .rightColumnCaption {
    grid-area: caption;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 64px;
  }
  .projImages {
    grid-area: img;
    display: flex;
    flex-direction: row;
    justify-self: end;
  }
  .articleCoverImg {
    margin: 0 16px;
    width: 300px;
  }
  .articleCoverImg:last-child {
    margin: 0 0 0 16px;
  }

  @media screen and (max-width: 1500px) {
    h1 {
      font-size: 64px;
    }
    .articleCoverImg {
      width: 250px;
    }
    p {
      font-size: 24px;
    }
    .quoteAuthor {
      font-size: 21px;
    }
    .rightColumnCaption {
      margin-top: 48px;
    }
  }
  @media screen and (max-width: 1300px) {
    h1 {
      font-size: 54px;
    }
    .articleCoverImg {
      width: 225px;
      margin: 0 12px;
    }
    .articleCoverImg:last-child {
      margin: 0 0 0 12px;
    }
    .quote {
      width: 90%;
    }
    button {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 1180px) {
    .articleCoverImg {
      width: 200px;
      margin: 0 8px;
    }
    .articleCoverImg:last-child {
      margin: 0 0 0 8px;
    }
    p {
      font-size: 21px;
    }
    .quoteAuthor {
      font-size: 18px;
    }
    .rightColumnCaption {
      margin-top: 32px;
    }
  }
  @media screen and (max-width: 1080px) {
    h1 {
      font-size: 48px;
    }
    .articleCoverImg {
      width: 175px;
      margin: 0 6px;
    }
    .articleCoverImg:last-child {
      margin: 0 0 0 6px;
    }
  }
  @media screen and (max-width: 932px) {
    h1 {
      font-size: 40px;
    }
    p {
      font-size: 18px;
    }
    .quoteAuthor {
      font-size: 15px;
    }
    button {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 860px) {
    .articleCoverImg {
      justify-self: end;
      width: 150px;
      margin: 0 4px;
    }
    .articleCoverImg:last-child {
      display: block;
      margin: 0 0 0 4px;
    }
  }
  @media screen and (max-width: 780px) {
    .projImages {
      width: 100%;
      justify-self: start;
      justify-content: space-between;
      margin-top: 48px;
    }
    .articleCoverImg {
      width: 49%;
      margin: 0;
    }
    .articleCoverImg:last-child {
      display: none;
    }
    h1 {
      font-size: 48px;
    }
    p {
      font-size: 21px;
    }
    .quote {
      width: 95%;
    }
    .quoteAuthor {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 650px) {
    p {
      font-size: 18px;
    }
    .quoteAuthor {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 480px) {
    h1 {
      font-size: 40px;
    }
    button {
      font-size: 16px;
    }
    p {
      font-size: 16px;
    }
    .quoteAuthor {
      font-size: 13px;
    }
  }
  @media screen and (max-width: 415px) {
    button {
      font-size: 12px;
    }
    p {
      font-size: 14px;
    }
    .quoteAuthor {
      font-size: 13px;
    }
  }
`;

const TutubiyaheSection = styled.div`
  background: #fdeae5;
  padding: 64px 38px 100px 64px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "title img img"
    "caption caption caption";

  #tutubiyahe {
    grid-area: img;
    justify-self: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  @media screen and (max-width: 1080px) {
    padding: 48px 28px 80px 48px;
  }
  @media screen and (max-width: 932px) {
    padding: 32px 24px 80px 32px;
  }
  @media screen and (max-width: 780px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "title"
      "img"
      "caption";

    #tutubiyahe {
      margin-top: 48px;
    }
  }
`;
const CampaignsSection = styled.div`
  background: #fbcdcd;
  padding: 64px 38px 100px 64px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "title img img"
    "caption caption caption";

  #campaigns {
    grid-area: img;
    justify-self: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  @media screen and (max-width: 1080px) {
    padding: 48px 28px 80px 48px;
  }
  @media screen and (max-width: 932px) {
    padding: 32px 24px 80px 32px;
  }
  @media screen and (max-width: 780px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "title"
      "img"
      "caption";
    #campaigns {
      margin-top: 48px;
    }
  }
`;
const TalksSection = styled.div`
  background: #fcdfc9;
  padding: 64px 38px 100px 64px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "title img img"
    "caption caption caption"
    ". img2 img2"
    "caption2 caption2 caption2";

  #talks {
    grid-area: img;
    justify-self: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  @media screen and (max-width: 1080px) {
    padding: 48px 28px 80px 48px;
  }
  @media screen and (max-width: 932px) {
    padding: 32px 24px 80px 32px;
  }
  @media screen and (max-width: 780px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "title"
      "img"
      "caption";
    #talks {
      margin-top: 48px;
    }
  }
`;

const ArticleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: end;
  width: 95%;
  grid-area: img;
  .articleHalfDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .articleHalfDiv:last-child {
    margin-top: 32px;
  }
  .articleHorizontalImg {
    width: 100%;
  }
  .articleQuote {
    text-align: justify;
    width: 100% !important;
    margin-bottom: 48px;
    span {
      font-family: "Eames Century Modern";
      font-style: italic;
    }
  }
  @media screen and (max-width: 780px) {
    width: 100%;
    .articleImages {
      margin: 0;
    }
    .articleImg {
      width: 49%;
    }
    .articleImg:last-child {
      display: none;
    }
    .articleQuote {
      margin: 16px 0 0;
    }
    .articleHalfDiv {
      flex-direction: column-reverse;
    }
  }
`;

const MottoSection = styled.div`
  grid-area: caption;
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-items: center;
  width: max-content;
  margin-top: 128px;
  h3 {
    font-family: "Eames Century Modern";
    font-weight: bold;
    font-size: 35px;
    width: max-content;
    color: #f36d4b;
  }
  @media screen and (max-width: 1300px) {
    h3 {
      font-size: 32px;
    }
  }
  @media screen and (max-width: 1100px) {
    h3 {
      font-size: 28px;
    }
    .laanFooterLogo {
      width: 40px;
    }
  }
  @media screen and (max-width: 850px) {
    h3 {
      font-size: 24px;
    }
    .laanFooterLogo {
      width: 35px;
    }
  }
  @media screen and (max-width: 650px) {
    margin-top: 96px;
    h3 {
      font-size: 21px;
    }
    .laanFooterLogo {
      width: 32px;
    }
  }
  @media screen and (max-width: 600px) {
    h3 {
      font-size: 18px;
    }
    .laanFooterLogo {
      width: 30px;
    }
  }
  @media screen and (max-width: 500px) {
    h3 {
      font-size: 15px;
    }
    .laanFooterLogo {
      width: 28px;
    }
  }
  @media screen and (max-width: 430px) {
    h3 {
      font-size: 13px;
    }
    .laanFooterLogo {
      width: 28px;
    }
  }
  @media screen and (max-width: 350px) {
    h3 {
      font-size: 11px;
    }
    .laanFooterLogo {
      width: 26px;
    }
  }
`;

// function ToggleSection(sectionName) {
//   const [tbClicked, setTbClicked] = React.useState(false);
//   if (sectionName === "tutubiyahe") {
//     if (tbClicked) {
//       document.getElementById("tutubiyaheImg").style.display = "block";
//       document.getElementById("tutubiyaheCaption").style.display = "block";
//       setTbClicked(false);
//     } else {
//       document.getElementById("tutubiyaheImg").style.display = "none";
//       document.getElementById("tutubiyaheCaption").style.display = "none";
//       setTbClicked(true);
//     }
//   }
// }
function Projects() {
  const { ProjectsHeroImage, SEOImg } = useStaticQuery(graphql`
    query ProjectImages {
      ProjectsHeroImage: file(
        relativePath: { eq: "Projects/ProjectsHeroBG.JPG" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1080
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
      SEOImg: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const ProjectsHero = getImage(ProjectsHeroImage);
  const backgroundFluidImageStack = [
    `linear-gradient(rgba(0,0,0, 0.5), rgba(0, 0, 0, 0.5))`,
    ProjectsHero,
  ];
  const [isClickedTUTU, setIsClickedTUTU] = useState(false);
  const [isClickedCP, setIsClickedCP] = useState(false);
  const [isClickedTALKS, setIsClickedTALKS] = useState(false);

  function toggleTUTU() {
    gsap.fromTo("#tutubiyahe", { opacity: 0 }, { opacity: 1, duration: 1 });
    if (!isClickedTUTU) {
      document.getElementById(
        "tutuButton"
      ).innerHTML = `<svg width="60" height="16" viewBox="0 0 60 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.292896 7.29289C-0.0976257 7.68342 -0.0976257 8.31658 0.292896 8.70711L6.65686 15.0711C7.04738 15.4616 7.68055 15.4616 8.07107 15.0711C8.4616 14.6805 8.4616 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.4616 1.95262 8.4616 1.31946 8.07107 0.928932C7.68055 0.538408 7.04738 0.538408 6.65686 0.928932L0.292896 7.29289ZM60 7L1 7V9L60 9V7Z" fill="#EF4459"/>
        </svg>
        `;
      document.getElementById("tutuButton").style.border = "none";
      // document.getElementById("tutuButton").style.fontSize = "40px";
    } else {
      document.getElementById("tutuButton").innerHTML = "READ MORE";
      document.getElementById("tutuButton").style.border = "4px solid #f36f4b";
      // document.getElementById("tutuButton").style.fontSize = "20px";
    }
    setIsClickedTUTU(!isClickedTUTU);
  }
  function toggleCP() {
    gsap.fromTo("#campaigns", { opacity: 0 }, { opacity: 1, duration: 1 });
    if (!isClickedCP) {
      document.getElementById(
        "cpButton"
      ).innerHTML = `<svg width="60" height="16" viewBox="0 0 60 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.292896 7.29289C-0.0976257 7.68342 -0.0976257 8.31658 0.292896 8.70711L6.65686 15.0711C7.04738 15.4616 7.68055 15.4616 8.07107 15.0711C8.4616 14.6805 8.4616 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.4616 1.95262 8.4616 1.31946 8.07107 0.928932C7.68055 0.538408 7.04738 0.538408 6.65686 0.928932L0.292896 7.29289ZM60 7L1 7V9L60 9V7Z" fill="#EF4459"/>
        </svg>
        `;
      document.getElementById("cpButton").style.border = "none";
    } else {
      document.getElementById("cpButton").innerHTML = "READ MORE";
      document.getElementById("cpButton").style.border = "4px solid #f36f4b";
    }
    setIsClickedCP(!isClickedCP);
  }
  function toggleTALKS() {
    gsap.fromTo("#talks", { opacity: 0 }, { opacity: 1, duration: 1 });
    if (!isClickedTALKS) {
      document.getElementById(
        "talksButton"
      ).innerHTML = `<svg width="60" height="16" viewBox="0 0 60 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.292896 7.29289C-0.0976257 7.68342 -0.0976257 8.31658 0.292896 8.70711L6.65686 15.0711C7.04738 15.4616 7.68055 15.4616 8.07107 15.0711C8.4616 14.6805 8.4616 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.4616 1.95262 8.4616 1.31946 8.07107 0.928932C7.68055 0.538408 7.04738 0.538408 6.65686 0.928932L0.292896 7.29289ZM60 7L1 7V9L60 9V7Z" fill="#EF4459"/>
        </svg>
        `;
      document.getElementById("talksButton").style.border = "none";
    } else {
      document.getElementById("talksButton").innerHTML = "READ MORE";
      document.getElementById("talksButton").style.border = "4px solid #f36f4b";
    }
    setIsClickedTALKS(!isClickedTALKS);
  }
  return (
    <Layout identifier="Projects">
      <SEO title="Projects" image={SEOImg.childImageSharp.fluid} />
      <ProjectsSection>
        <CheatWrapper>
          <CoverPage image={backgroundFluidImageStack}>
            <StaticImage
              src="../images/OurWork/OurWorkLogoLAAN.png"
              placeholder="blurred"
              width={303.75}
              alt=""
              id="LOGO"
            ></StaticImage>

            <h1 style={{ marginBottom: "0" }}>
              Become a part of our change. Be a part of the passionate community
              that advocates for public health, through a diversity of projects
              to empower and raise awareness about what Project LAAN stands for.
            </h1>

            <svg
              className="arrowBig"
              style={{ marginTop: "52px" }}
              width="30"
              height="88"
              viewBox="0 0 30 88"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5858 87.4142C14.3668 88.1953 15.6332 88.1953 16.4142 87.4142L29.1421 74.6863C29.9232 73.9052 29.9232 72.6389 29.1421 71.8579C28.3611 71.0768 27.0948 71.0768 26.3137 71.8579L15 83.1716L3.68629 71.8579C2.90524 71.0768 1.63891 71.0768 0.857864 71.8579C0.0768156 72.6389 0.0768156 73.9052 0.857864 74.6863L13.5858 87.4142ZM13 0L13 86H17L17 0L13 0Z"
                fill="white"
              />
            </svg>

            <svg
              className="arrowSmall"
              width="16"
              height="55"
              viewBox="0 0 16 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.29289 54.7071C7.68342 55.0976 8.31658 55.0976 8.70711 54.7071L15.0711 48.3432C15.4616 47.9526 15.4616 47.3195 15.0711 46.9289C14.6805 46.5384 14.0474 46.5384 13.6569 46.9289L8 52.5858L2.34315 46.9289C1.95262 46.5384 1.31946 46.5384 0.928932 46.9289C0.538408 47.3195 0.538408 47.9526 0.928932 48.3432L7.29289 54.7071ZM7 0L7 54H9L9 0L7 0Z"
                fill="white"
              />
            </svg>
          </CoverPage>
          <ProjectsGlobalWrapper>
            <TutubiyaheSection>
              <div className="projTitleCTA">
                <h1>TUTUBIYAHE</h1>

                <button onClick={toggleTUTU} id="tutuButton">
                  READ MORE
                </button>
              </div>
              {!isClickedTUTU && (
                <div className="projImages" id="tutubiyaheImg">
                  <StaticImage
                    src="../images/Projects/Tutubiyahe/ProjectsTutubiyaheVerticalCover-1.jpg"
                    placeholder="blurred"
                    width={350}
                    alt=""
                    className="articleCoverImg"
                    quality={90}
                  ></StaticImage>
                  <StaticImage
                    src="../images/Projects/Tutubiyahe/ProjectsTutubiyaheVerticalCover-2.jpg"
                    placeholder="blurred"
                    width={350}
                    alt=""
                    className="articleCoverImg"
                    quality={90}
                  ></StaticImage>
                  <StaticImage
                    src="../images/Projects/Tutubiyahe/ProjectsTutubiyaheVerticalCover-3.jpg"
                    placeholder="blurred"
                    width={350}
                    alt=""
                    className="articleCoverImg"
                    quality={90}
                  ></StaticImage>
                </div>
              )}
              {!isClickedTUTU && (
                <div className="rightColumnCaption" id="tutubiyaheCaption">
                  <p className="quote">
                    “Seeing the community for the first time and being given the
                    oppurtunity to look around was really stirring for me
                    because it allowed me to better understand the ‘why’ of our
                    organization. Actually being there helped me realize the
                    urgency of our work and why it's more important now than
                    ever to push for health for all.”
                  </p>
                  <p style={{ marginBottom: "8px" }} className="quoteAuthor">
                    Lizette Aurellano
                  </p>
                </div>
              )}
              <section id="tutubiyahe">
                {isClickedTUTU && (
                  <ArticleSection>
                    <div className="articleHalfDiv">
                      <p className="quote articleQuote">
                        Each community visit or <i>Tutubiyahe</i> is a unique
                        experience for communities to learn about their Rights
                        to Health, and for Project LAAN members to better
                        understand and contextualize Public Health.
                      </p>
                      <StaticImage
                        src="../images/Projects/Tutubiyahe/ProjectsTutubiyaheHorizontalArticle-1.jpeg"
                        placeholder="blurred"
                        alt=""
                        className="articleHorizontalImg"
                        quality={90}
                      ></StaticImage>
                    </div>
                    <div className="articleHalfDiv">
                      <p className="quote articleQuote">
                        Past Tutubiyahe visits have included subjects like&nbsp;
                        <span>
                          Road Safety, Health Modules, &amp; Community Bonding.
                        </span>
                      </p>
                      <div className="projImages articleImages">
                        <StaticImage
                          src="../images/Projects/Tutubiyahe/ProjectsTutubiyaheVerticalArticle-1.jpg"
                          placeholder="blurred"
                          width={350}
                          alt=""
                          className="articleImg"
                          quality={90}
                        ></StaticImage>
                        <StaticImage
                          src="../images/Projects/Tutubiyahe/ProjectsTutubiyaheVerticalArticle-2.jpg"
                          placeholder="blurred"
                          width={350}
                          alt=""
                          className="articleImg"
                          quality={90}
                        ></StaticImage>
                        <StaticImage
                          src="../images/Projects/Tutubiyahe/ProjectsTutubiyaheVerticalArticle-3.jpg"
                          placeholder="blurred"
                          width={350}
                          alt=""
                          className="articleImg"
                          quality={90}
                        ></StaticImage>
                      </div>
                    </div>
                  </ArticleSection>
                )}
              </section>
              {isClickedTUTU && (
                <MottoSection>
                  <h3>#FIGHTING POVERTY THROUGH HEALTH&nbsp;</h3>
                  <StaticImage
                    src="../images/footer/laan-footer.png"
                    placeholder="blurred"
                    width={50}
                    alt=""
                    className="laanFooterLogo"
                    quality={90}
                  ></StaticImage>
                </MottoSection>
              )}
            </TutubiyaheSection>
            <CampaignsSection>
              <div className="projTitleCTA">
                <h1>CAMPAIGNS</h1>
                <button onClick={toggleCP} id="cpButton">
                  READ MORE
                </button>
              </div>
              {!isClickedCP && (
                <div className="projImages">
                  <StaticImage
                    src="../images/Projects/Campaigns/ProjectsCampaignsVerticalCover-1.jpeg"
                    placeholder="blurred"
                    width={350}
                    alt=""
                    className="articleCoverImg"
                    quality={90}
                  ></StaticImage>
                  <StaticImage
                    src="../images/Projects/Campaigns/ProjectsCampaignsVerticalCover-2.jpeg"
                    placeholder="blurred"
                    width={350}
                    alt=""
                    className="articleCoverImg"
                    quality={90}
                  ></StaticImage>
                  <StaticImage
                    src="../images/Projects/Campaigns/ProjectsCampaignsVerticalCover-3.jpg"
                    placeholder="blurred"
                    width={350}
                    alt=""
                    className="articleCoverImg"
                    quality={90}
                  ></StaticImage>
                </div>
              )}
              {!isClickedCP && (
                <div className="rightColumnCaption" id="rightColumnCampaigns">
                  <p className="quote">
                    “We wanted to campaign not only for the mental health of
                    Ateneans but also for those in the margins — underprivileged
                    Filipinos who experience the same issues but lack
                    accessibility for 'help'.”
                  </p>
                  <p style={{ marginBottom: "8px" }} className="quoteAuthor">
                    Bea Basbas
                  </p>
                </div>
              )}
              <section id="campaigns">
                {isClickedCP && (
                  <ArticleSection>
                    <div className="articleHalfDiv">
                      <p className="quote articleQuote">
                        What makes each campaign special is the timely and
                        comprehensive topics that strive to communicate the
                        importance of Health for All and understand social
                        structures that emphasize Fighting Poverty Through
                        Health.
                      </p>
                      <StaticImage
                        src="../images/Projects/Campaigns/ProjectsCampaignsHorizontalArticle-1.jpeg"
                        placeholder="blurred"
                        alt=""
                        className="articleHorizontalImg"
                        quality={90}
                      ></StaticImage>
                    </div>
                    <div className="articleHalfDiv">
                      <p className="quote articleQuote">
                        Early Campaigns have tackled topics ranging from&nbsp;
                        <span>
                          Health Fitness, Seasonal Diseases, &amp; Mental Health
                          Hygiene.
                        </span>
                      </p>
                      <div className="projImages articleImages">
                        <StaticImage
                          src="../images/Projects/Campaigns/ProjectsCampaignsVerticalArticle-1.jpg"
                          placeholder="blurred"
                          width={350}
                          alt=""
                          className="articleImg"
                          quality={90}
                        ></StaticImage>
                        <StaticImage
                          src="../images/Projects/Campaigns/ProjectsCampaignsVerticalArticle-2.jpg"
                          placeholder="blurred"
                          width={350}
                          alt=""
                          className="articleImg"
                          quality={90}
                        ></StaticImage>
                        <StaticImage
                          src="../images/Projects/Campaigns/ProjectsCampaignsVerticalArticle-3.jpg"
                          placeholder="blurred"
                          width={350}
                          alt=""
                          className="articleImg"
                          quality={90}
                        ></StaticImage>
                      </div>
                    </div>
                  </ArticleSection>
                )}
              </section>
              {isClickedCP && (
                <MottoSection>
                  <h3>#FIGHTING POVERTY THROUGH HEALTH&nbsp;</h3>
                  <StaticImage
                    src="../images/footer/laan-footer.png"
                    placeholder="blurred"
                    width={50}
                    alt=""
                    className="laanFooterLogo"
                    quality={100}
                  ></StaticImage>
                </MottoSection>
              )}
            </CampaignsSection>
            <TalksSection>
              <div className="projTitleCTA">
                <h1>TALKS</h1>
                <button onClick={toggleTALKS} id="talksButton">
                  READ MORE
                </button>
              </div>
              {!isClickedTALKS && (
                <div className="projImages">
                  <StaticImage
                    src="../images/Projects/Talks/ProjectsTalksVerticalCover-1.jpg"
                    placeholder="blurred"
                    width={350}
                    alt=""
                    className="articleCoverImg"
                    quality={90}
                  ></StaticImage>
                  <StaticImage
                    src="../images/Projects/Talks/ProjectsTalksVerticalCover-2.jpg"
                    placeholder="blurred"
                    width={350}
                    alt=""
                    className="articleCoverImg"
                    quality={90}
                  ></StaticImage>
                  <StaticImage
                    src="../images/Projects/Talks/ProjectsTalksVerticalCover-3.jpg"
                    placeholder="blurred"
                    width={350}
                    alt=""
                    className="articleCoverImg"
                    quality={90}
                  ></StaticImage>
                </div>
              )}
              {!isClickedTALKS && (
                <div className="rightColumnCaption" id="rightColumnTalks">
                  <p className="quote">
                    “What I can say about the teams working on all these talks
                    is that they were definitely not afraid to push the
                    boundaries in what Discourse in terms of LAAN could be. They
                    never fail to surprise me. Anyone can think up a topic and
                    reserve a venue but it takes a special group of people to
                    get that talk to make an impact and get that message
                    across.”
                  </p>
                  <p style={{ marginBottom: "8px" }} className="quoteAuthor">
                    Margarita Antonio
                  </p>
                </div>
              )}
              <section id="talks">
                {isClickedTALKS && (
                  <ArticleSection>
                    <div className="articleHalfDiv">
                      <p className="quote articleQuote">
                        Two things characterize a Project LAAN talk—an avenue
                        for an informative discourse, and a topic that
                        intersects public health with other social structures
                        showing how Public Health Starts with You.
                      </p>
                      <StaticImage
                        src="../images/Projects/Talks/ProjectsTalksHorizontalArticle-1.jpg"
                        placeholder="blurred"
                        alt=""
                        className="articleHorizontalImg"
                        quality={90}
                      ></StaticImage>
                    </div>
                    <div className="articleHalfDiv">
                      <p className="quote articleQuote">
                        Previous Talks have taken on discussions centering
                        on&nbsp;
                        <span>
                          Health and Sustainability, Sex Education, &amp; the
                          Annual Universal Health Coverage Summit.
                        </span>
                      </p>
                      <div className="projImages articleImages">
                        <StaticImage
                          src="../images/Projects/Talks/ProjectsTalksVerticalArticle-1.jpg"
                          placeholder="blurred"
                          width={350}
                          alt=""
                          className="articleImg"
                          quality={90}
                        ></StaticImage>
                        <StaticImage
                          src="../images/Projects/Talks/ProjectsTalksVerticalArticle-2.jpg"
                          placeholder="blurred"
                          width={350}
                          alt=""
                          className="articleImg"
                          quality={90}
                        ></StaticImage>
                        <StaticImage
                          src="../images/Projects/Talks/ProjectsTalksVerticalArticle-3.jpg"
                          placeholder="blurred"
                          width={350}
                          alt=""
                          className="articleImg"
                          quality={90}
                        ></StaticImage>
                      </div>
                    </div>
                  </ArticleSection>
                )}
              </section>
              {isClickedTALKS && (
                <MottoSection>
                  <h3>#FIGHTING POVERTY THROUGH HEALTH&nbsp;</h3>
                  <StaticImage
                    src="../images/footer/laan-footer.png"
                    placeholder="blurred"
                    width={50}
                    alt=""
                    className="laanFooterLogo"
                    quality={100}
                  ></StaticImage>
                </MottoSection>
              )}
            </TalksSection>
          </ProjectsGlobalWrapper>
        </CheatWrapper>
      </ProjectsSection>
    </Layout>
  );
}

export default Projects;
